.msgBoxContainer {
  width: 100%;
  height: 10vh;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.msgBox {
  padding: 1.5vh 2vh;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 1vh;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.msgBox p {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Roboto";
  font-size: 2vh;
  font-weight: 100;
  line-height: 3vh;
}

.fade {
  opacity: 0;
}
