.historySec {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.historyContainer {
  width: 80%;
  height: 85%;
  display: flex;
  flex-direction: column;
}
.historyContainer.mob {
  width: 90%;
  height: 90%;
}

.historyLabel p {
  font-family: "Roboto";
  font-size: 3vh;
  line-height: 3vh;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  text-decoration: underline rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.historyLabel > p:hover {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: underline rgba(255, 255, 255, 0.7);
}

.hsLogIn {
  width: 33%;
  align-self: center;
  flex: 1;
  margin-top: 5vh;
}
.hsLogIn.mob {
  width: 100%;
}

/*HISTORY*/
/*HISTORY*/
/*HISTORY*/
/*HISTORY*/

.histories {
  width: 100%;
  flex: 1;
  margin-top: 5vh;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.historiesScroll {
  width: 100%;
  height: 95%;
}

.historiesContain {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.hsItemContain {
  --height: 17vh;
  width: 30%;
  height: var(--height);
  margin: 1.5%;
}

.hsItemContain.mob {
  --height: 18vh;
  width: 90%;
  height: var(--height);
  margin: 5%;
}
