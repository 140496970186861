.hpContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hpLinks {
  width: 80%;
  height: 3vh;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 10%;
  bottom: 5vh;
}

.hpLinks > p {
  font-family: "Roboto";
  font-size: 3vh;
  line-height: 3vh;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  text-decoration: underline rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hpLinks > p:hover {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: underline rgba(255, 255, 255, 0.7);
}

.hpContent {
  width: 80%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.hpContent.mob {
  margin-top: -5vh;
}

/*QR CODE */
.hpLabel > p {
  font-family: "Roboto";
  font-size: 4vh;
  line-height: 5vh;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}

.hpLabel > p > span {
  cursor: pointer;
  text-decoration: underline rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hpLabel > p > span:hover {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: underline rgba(255, 255, 255, 0.7);
}

.hpQRcode {
  width: 15vw;
  height: 15vw;
  overflow: hidden;
  border-radius: 1vh;
}
.hpQRcode.mob {
  width: 30vh;
  height: 30vh;
  overflow: hidden;
  border-radius: 1vh;
}

.hpQRcode svg {
  width: 100%;
  height: 100%;
}

.hpInput {
  width: 30vw;
  height: 5vh;
  border-radius: 2vh;
  border: 0.2vh solid rgba(255, 255, 255, 0.9);
  display: flex;
  overflow: hidden;
}

.hpInput.mob {
  width: 80vw;
  height: 5vh;
  border-radius: 2vh;
  border: 0.2vh solid rgba(255, 255, 255, 0.9);
  display: flex;
  overflow: hidden;
}

.hpInput > p {
  font-family: "Roboto";
  font-size: 2vh;
  padding: 0 2vh;
  line-height: 5vh;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
}

.canvas {
  position: absolute;
  opacity: 0;
  z-index: -99999;
}

.hpInput > p:hover {
  color: rgba(255, 255, 255, 0.7);
}

.hpInput > input {
  flex: 1;
  height: 5vh;
  margin-left: 2vh;
  border: none;
  background: transparent;
  font-family: "Roboto";
  font-size: 2vh;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
}

.hpInput > input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */

  color: rgba(255, 255, 255, 0.5);
}
