.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chrome {
  width: 100%;
  height: 90vh;
  overflow: hidden;
}

.swiper {
  width: 100%;
  height: 200vh;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.swiper.chrm {
  width: 100%;
  height: 180vh;
  display: flex;
  flex-direction: column;
}

.swiper.swipe.chrm {
  margin-top: -90vh;
}

.swiper.swipe {
  margin-top: -100vh;
}

.siperPage {
  width: 100%;
  height: 100vh;
}

.siperPage.chrm {
  width: 100%;
  height: 90vh;
}
