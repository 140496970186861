.hsItem {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 2vh;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 0.1vh solid rgba(255, 255, 255, 0.5);
}

.hsLeft {
  width: var(--height);
  height: var(--height);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hsQrContainer {
  width: 70%;
  height: 70%;
  border-radius: 1vh;
  overflow: hidden;
}

.hsQrContainer > svg {
  width: 100%;
  height: 100%;
}

.hsRight {
  flex: 1;
  height: 70%;
  margin-right: 5%;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hsRightLebel {
  width: 100%;
  overflow: hidden;
}

.hsRightLebel > button {
  background: transparent;
  border: none;
  font-family: "Roboto";
  font-size: 2vh;
  line-height: 3vh;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  cursor: text;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hsRightLebel > button:hover,
.hsRightLebel > button:active {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
}
.hsRightLebel > p:nth-child(2) {
  font-family: "Roboto";
  font-size: 1.5vh;
  line-height: 3vh;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hsRight > p {
  font-family: "Roboto";
  font-size: 3vh;
  text-align: right;
  cursor: pointer;
  line-height: 5vh;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: underline rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hsRight > p:hover {
  color: rgba(255, 255, 255, 0.7);
}
