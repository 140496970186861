@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
* {
  list-style: none;
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  /*overflow:hidden;*/
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.no_result{
  color: white;
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.3)
}
input:focus {
  border: none;
}

input:active {
  border: none;
}

::selection {
  background: hsla(0, 0%, 1%, 0.158); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: hsla(0, 0%, 1%, 0.158); /* Gecko Browsers */
}
.hide {
  display: none;
}
