.loadingDiv {
  width: 100%;
  height: 100%;
  display: flex;
}

.loadingLeft {
  width: var(--height);
  height: var(--height);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadCircle {
  width: 75%;
  height: 75%;
  border-radius: 25vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-duration: 1.5s;
}

.loadingRight {
  flex: 1;
  height: 70%;
  margin-right: 5%;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lrTop {
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.lrTop > div:nth-child(1) {
  width: 100%;
  height: 60%;
  background-color: rgba(0, 0, 0, 0.1);
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-duration: 1.5s;
}

.lrTop > div:nth-child(2) {
  width: 100%;
  height: 30%;
  background-color: rgba(0, 0, 0, 0.1);
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-duration: 1.5s;
}

.leBottom {
  width: 50%;
  height: 20%;
  background-color: rgba(0, 0, 0, 0.1);
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-duration: 1.5s;
}

@keyframes blink {
  0% {
    background-color: rgba(1, 1, 1, 0.05);
  }
  50% {
    background-color: rgba(1, 1, 1, 0.1);
  }
  100% {
    background-color: rgba(1, 1, 1, 0.05);
  }
}
